import React from 'react';

import { useParams } from 'react-router-dom';

import ViewOwnerPage from '../ViewOwnerPage/ViewOwnerPage';

const ViewSignleOwnerPage = () => {
  const { ownerId } = useParams();
  return <ViewOwnerPage ownerId={ownerId} />;
};

export default ViewSignleOwnerPage;
