import React, { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import './AdminPage.css';
import {
  useRouteMatch,
  Link,
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../slice/agentSlice';
import PropertiesPage from '../Properties/PropertiesPage';
import CreateOwnerPage from '../CreateOwner/CreateOwnerPage';
import { selectIsAdmin } from '../../slice/agentSlice';
import UpdateOwnerPage from '../UpdateOwner/UpdateOwnerPage';
import ViewSingleOwnerPage from '../ViewSingleOwnerPage/ViewSingleOwnerPage';
import CreatePropertyPage from '../CreateProperty/CreatePropertyPage';
import UpdatePropertyPage from '../UpdateProperty/UpdatePropertyPage';
import { fetchTypes } from '../../slice/typesSlice';
import DashboardPage from '../Dashboard/DashboardPage';
import CreateAgentPage from '../CreateAgent/CreateAgentPage';
import UpdateAgentPage from '../UpdateAgent/UpdateAgentPage';
import ViewAgentPage from '../ViewAgentPage/ViewAgentPage';
const { Header, Content, Footer } = Layout;

const AdminPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const signout = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <Layout className='layout'>
      <Header>
        <span className='logo'>iProLand Platform</span>
        <Menu theme='dark' mode='horizontal'>
          <Menu.Item key='1'>
            <Link to={`${match.url}/dashboard`}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key='2'>
            <Link to={`${match.url}/properties`}>Properties</Link>
          </Menu.Item>
          {isAdmin ? (
            <Menu.Item key='3'>
              <Link to={`${match.url}/agents`}>Agents</Link>
            </Menu.Item>
          ) : null}

          <span className='admin-page-sign-out' onClick={signout}>
            Sign Out
          </span>
        </Menu>
      </Header>
      <Content>
        <div className='site-layout-content'>
          <Switch>
            <Route path={`${match.path}/dashboard`}>
              <DashboardPage />
            </Route>
            <Route path={`${match.path}/properties/create`}>
              <CreatePropertyPage />
            </Route>
            <Route path={`${match.path}/owners/create`}>
              <CreateOwnerPage />
            </Route>
            <Route path={`${match.path}/agents/create`}>
              <CreateAgentPage />
            </Route>
            <Route path={`${match.path}/owners/:ownerId/update`}>
              {isAdmin ? <UpdateOwnerPage /> : <Redirect to='/admin' />}
            </Route>
            <Route path={`${match.path}/agents/:agentId/update`}>
              {isAdmin ? <UpdateAgentPage /> : <Redirect to='/admin' />}
            </Route>
            <Route path={`${match.path}/properties/:propertyId/update`}>
              <UpdatePropertyPage />
            </Route>
            <Route path={`${match.path}/owners/:ownerId`}>
              <ViewSingleOwnerPage />
            </Route>
            <Route path={`${match.path}/agents/`}>
              {isAdmin ? <ViewAgentPage /> : <Redirect to='/admin' />}
            </Route>
            <Route path={`${match.path}/properties`}>
              <PropertiesPage />
            </Route>
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        iProLand Real Estate ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default AdminPage;
