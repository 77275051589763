import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import agentReducer from './agentSlice'
import typesReducer from './typesSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    agent: agentReducer,
    types: typesReducer
  },
});

export default store;