import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { selectName } from '../../slice/agentSlice';
import './CreateOwnerPage.css';
import axiosInstance from '../../util/api';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreateOwnerPage = () => {
  const [form] = Form.useForm();
  const agentName = useSelector(selectName);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    console.log(values);
    await createOwner(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const createOwner = async (values) => {
    delete values.agent;
    setLoading(true);
    try {
      await axiosInstance.post('/owners', values);
      message.success('Owner successfully created');
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='create-owner-page-container'>
      <h2>Create owner</h2>
      <Form
        {...layout}
        name='basic'
        initialValues={{
          agent: agentName,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='create-owner-page-form'
        form={form}
      >
        <Form.Item
          label='Name'
          name='name'
          rules={[
            {
              required: true,
              message: "Please input owner's name!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Surname'
          name='surname'
          rules={[
            {
              required: true,
              message: "Please input owner's surname!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Contact number'
          name='contactNumber'
          rules={[
            {
              required: true,
              message: "Please input owner's number!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Address' name='address'>
          <Input />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Added by' name='agent'>
          <Input disabled={true} style={{ width: 250 }}/>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit' disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateOwnerPage;
