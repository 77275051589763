import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Row } from 'antd';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/api';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../slice/agentSlice';
import PropertiesTable from '../../components/PropertiesTable/PropertiesTable';
import './ViewOwnerPage.css';

const ViewOwnerPage = ({ ownerId }) => {
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const [owner, setOwner] = useState({
    name: 'a',
    surname: 'v',
    contactNumber: '',
    address: '',
    email: '',
    agent: { id: 0, name: '' },
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: false,
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const ownerResponse = await axiosInstance.get(`/owners/${ownerId}`);
        setOwner(ownerResponse.data);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ownerId);
  }, [ownerId]);

  useEffect(() => {
    fetchTableData({ pagination });
  }, [ownerId]);

  const fetchTableData = async ({ pagination }) => {
    setTableLoading(true);
    try {
      const dataResponse = await axiosInstance.get(
        `/properties?pageNumber=${pagination.current - 1}&ownerId=${ownerId}`
      );
      setTableData(dataResponse.data.searchResults);
      setPagination({ ...pagination, total: dataResponse.data.totalPages*pagination.pageSize });
    } finally {
      setTableLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({ pagination });
  };

  return (
    <>
      <Row className='view-owner-page-descriptions'>
        <Col offset={1} span={22}>
          <Descriptions
            title='Owner Info'
            bordered
            extra={
              isAdmin && (
                <Button type='primary'>
                  <Link to={`/admin/owners/${ownerId}/update`}>
                    Update Owner
                  </Link>
                </Button>
              )
            }
            column={1}
            labelStyle={{
              width: '30%',
            }}
          >
            <Descriptions.Item label='Name'>{owner.name}</Descriptions.Item>
            <Descriptions.Item label='Surname'>
              {owner.surname}
            </Descriptions.Item>
            <Descriptions.Item label='Contact number'>
              {owner.contactNumber}
            </Descriptions.Item>
            <Descriptions.Item label='Address'>
              {owner.address}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>{owner.email}</Descriptions.Item>
            <Descriptions.Item label='Added By'>
              {owner.agent.name}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Row>
        <Col offset={1} span={22}>
          <PropertiesTable
            data={tableData}
            handleTableChange={handleTableChange}
            pagination={pagination}
            loading={tableLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default ViewOwnerPage;
