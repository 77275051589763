import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  InputNumber,
  Row,
  Select
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectAssetTypes,
  selectLocationTypes,
  selectPropertyTypes
} from '../../slice/typesSlice';
import { featuresOptions } from '../../util/constants';
import './TableSearchRow.css';

const { Option } = Select;
const { Panel } = Collapse;

const TableSearchRow = ({ handleTableSearch }) => {
  const [form] = Form.useForm();
  const assetTypes = useSelector(selectAssetTypes);
  const locationTypes = useSelector(selectLocationTypes);
  const propertyTypes = useSelector(selectPropertyTypes);
  const onFinish = async (values) => {
    console.log(values);
    handleTableSearch(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='create-owner-page-container'>
      <Form
        layout='inline'
        name='table-row-search'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item label='Asset type' name='assetTypeId'>
          <Select style={{ width: 150 }} allowClear>
            {assetTypes.map((assetType) => (
              <Option key={assetType.id} value={assetType.id}>
                {assetType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Property type' name='propertyTypeId'>
          <Select style={{ width: 300 }} allowClear mode="multiple">
            {propertyTypes.map((propertyType) => (
              <Option key={propertyType.id} value={propertyType.id}>
                {propertyType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Min Rate (€)' name='minRate'>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label='Max Rate (€)' name='maxRate'>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label='Number of bedrooms' name='numberOfBedrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Number of bathrooms' name='numberOfBathrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Location' name='locationTypeId'>
          <Select style={{ width: 300 }} allowClear mode="multiple">
            {locationTypes.map((locationType) => (
              <Option key={locationType.id} value={locationType.id}>
                {locationType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className='features-collapsable'>
          <Collapse>
            <Panel header='Features' key='1' showArrow={false}>
              <Form.Item noStyle={true} name='features'>
                <Checkbox.Group>
                  <Row>
                    {featuresOptions.map(({ label, value }) => {
                      return (
                        <Col key={value} span={8}>
                          <Checkbox
                            key={value}
                            value={value}
                            style={{ lineHeight: '32px' }}
                          >
                            {label}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Panel>
          </Collapse>
        </div>

        <Form.Item>
          <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TableSearchRow;
