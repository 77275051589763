import { Button, Form, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../util/api';
import './UpdateOwnerPage.css';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const { Option } = Select;

const UpdateOwnerPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState({
    name: 'a',
    surname: 'v',
    contactNumber: '',
    address: '',
    email: '',
    agent: { id: 0, name: '' },
  });
  const [agents, setAgents] = useState([]);
  const { ownerId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const ownerResponse = await axiosInstance.get(`/owners/${ownerId}`);
        const agentsResponse = await axiosInstance.get('/agents');
        setAgents(agentsResponse.data);
        setOwner(ownerResponse.data);
        const initialFields = ownerResponse.data;
        initialFields.agent = ownerResponse.data.agent.id;
        form.setFieldsValue(initialFields);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ownerId);
  }, [ownerId, form]);

  const onFinish = async (values) => {
    await updateOwner(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const updateOwner = async (values) => {
    const newOwner = values;
    newOwner.agent = {
      id: values.agent,
    };
    setLoading(true);
    try {
      await axiosInstance.put(`/owners/${ownerId}`, newOwner);
      message.success('Owner successfully updated');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='create-owner-page-container'>
      <h2>Update owner</h2>
      <Form
        {...layout}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='update-owner-page-form'
        form={form}
      >
        <Form.Item
          label='Name'
          name='name'
          rules={[
            {
              required: true,
              message: "Please input owner's name!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Surname'
          name='surname'
          rules={[
            {
              required: true,
              message: "Please input owner's surname!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Contact number'
          name='contactNumber'
          rules={[
            {
              required: true,
              message: "Please input owner's number!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Address' name='address'>
          <Input />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Added by' name='agent'>
          <Select>
            {agents.map((agent) => (
              <Option key={agent.id} value={agent.id}>
                {agent.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit' disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateOwnerPage;
