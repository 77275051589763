import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/api';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../slice/agentSlice';
import './ViewSingleAgentPage.css';

const ViewSingleAgentPage = ({ agentId }) => {
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState({});
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    const fetchData = async () => {
      if (agentId) {
        setLoading(true);
        try {
          const agentResponse = await axiosInstance.get(`/agents/${agentId}`);
          setAgent(agentResponse.data);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData(agentId);
  }, [agentId]);

  const handleBlock = async () => {
    await axiosInstance.post(`/agents/${agentId}/block`);
  };

  return isAdmin ? (
    <>
      <Row className='view-agent-page-descriptions'>
        <Col offset={1} span={22}>
          <Descriptions
            title='Agent Info'
            bordered
            extra={
              isAdmin &&
              agentId && (
                <Space>
                  <Button type='primary'>
                    <Link to={`/admin/agents/${agentId}/update`}>
                      Update Agent
                    </Link>
                  </Button>
                  <Button type='primary' danger onClick={handleBlock}>
                    Block Agent
                  </Button>
                </Space>
              )
            }
            column={1}
            labelStyle={{
              width: '30%',
            }}
          >
            <Descriptions.Item label='Name'>{agent.name}</Descriptions.Item>
            <Descriptions.Item label='Address'>
              {agent.address}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile number'>
              {agent.mobileNumber}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>{agent.email}</Descriptions.Item>
            <Descriptions.Item label='Id Number'>
              {agent.idNumber}
            </Descriptions.Item>
            <Descriptions.Item label='vatNumber'>
              {agent.vatNumber}
            </Descriptions.Item>
            <Descriptions.Item label='Branch Address'>
              {agent.branchAddress}
            </Descriptions.Item>
            <Descriptions.Item label='Nationality'>
              {agent.nationality}
            </Descriptions.Item>
            <Descriptions.Item label='Date of Birth'>
              {agent.dateOfBirth}
            </Descriptions.Item>
            <Descriptions.Item label='Iban'>{agent.iban}</Descriptions.Item>
            <Descriptions.Item label='Bank Name'>
              {agent.bankName}
            </Descriptions.Item>
            <Descriptions.Item label='Swift Code'>
              {agent.swiftCode}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  ) : null;
};

export default ViewSingleAgentPage;
