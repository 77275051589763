import { Button, Col, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/api';
import ViewSingleAgentPage from '../ViewSingleAgentPage/ViewSingleAgentPage';
import './ViewAgentPage.css';
const { Option } = Select;

const ViewAgentPage = () => {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(undefined);

  const handleAgentChange = (value) => {
    setSelectedAgent(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const agentsResponse = await axiosInstance.get('/agents');
      setAgents(agentsResponse.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Row className='view-agent-page-selection'>
        <Col offset={1} span={22}>
          <Space size='large'>
            <Select
              style={{ width: 300 }}
              onChange={handleAgentChange}
              placeholder='Select Agent'
            >
              {agents.map((agent) => (
                <Option key={agent.id} value={agent.id}>
                  {agent.name}
                </Option>
              ))}
            </Select>
            <Button type='primary'>
              <Link to={`/admin/agents/create`}>Add new agent</Link>
            </Button>
          </Space>
        </Col>
      </Row>
      <ViewSingleAgentPage agentId={selectedAgent} />;
    </>
  );
};

export default ViewAgentPage;
