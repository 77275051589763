import { Button, Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropertiesTable from '../../components/PropertiesTable/PropertiesTable';
import TableSearchRow from '../../components/TableSearchRow/TableSearchRow';
import axiosInstance from '../../util/api';
import './PropertiesPage.css';

const PropertiesPage = () => {
  const [data, setData] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: false,
    total: 500
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTableData({ pagination });
  }, [queryString]);

  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({ pagination });
  };

  const fetchTableData = async ({ pagination }) => {
    setLoading(true);
    try {
      const dataResponse = await axiosInstance.get(
        `/properties?pageNumber=${pagination.current - 1}` +
          (queryString ? `&${queryString}` : '')
      );
      setData(dataResponse.data.searchResults);
      setPagination({ ...pagination, total: dataResponse.data.totalPages*pagination.pageSize });
    } finally {
      setLoading(false);
    }
  };

  const handleTableSearch = async (values) => {
    const queryString = mapValuesToRequestString(values);
    setQueryString(queryString);
  };

  const mapValuesToRequestString = (values) => {
    const searchParams = new URLSearchParams();
    Object.keys(values).forEach((key) => {
      if (!values[key]) return;
      if (key === 'features') {
        values.features.forEach((key) => searchParams.append('features', key));
        return;
      }
      if (key === 'propertyTypeId') {
        values.propertyTypeId.forEach((propertyTypeId) =>
          searchParams.append('propertyTypeId', propertyTypeId)
        );
        return;
      }
      if (key === 'locationTypeId') {
        values.locationTypeId.forEach((locationTypeId) =>
          searchParams.append('locationTypeId', locationTypeId)
        );
        return;
      }
      searchParams.append(key, values[key]);
    });
    return searchParams.toString();
  };

  return (
    <>
      <Row className='properties-page-button-row'>
        <Col span={6} offset={1}>
          <Space>
            <Button type='primary'>
              <Link to={`/admin/owners/create`}>Create Owner</Link>
            </Button>

            <Button type='primary'>
              <Link to={`/admin/properties/create`}>Create Property</Link>
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={22}>
          <div className='properties-table-search-row'>
            <TableSearchRow handleTableSearch={handleTableSearch} />
          </div>
          <PropertiesTable
            data={data}
            handleTableChange={handleTableChange}
            pagination={pagination}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default PropertiesPage;
