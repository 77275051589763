import React from 'react';
import { FaBed, FaRegSquare } from 'react-icons/fa';
import './BedroomsSQM.css';

const BedroomsSQM = ({ numberOfBedrooms, squareMeters }) => {
  return (
    <div>
      <div className='space-align-block'>
        <FaBed size='15px' />
        <span>{numberOfBedrooms}</span>
      </div>
      <div className='space-align-block'>
        <FaRegSquare size='15px' />
        <span>{squareMeters}</span>
      </div>
    </div>
  );
};

export default BedroomsSQM;
