import {
  BulbOutlined,
  FileOutlined,
  NotificationOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../slice/agentSlice';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/api';

const TableOperations = ({ property }) => {
  const isAdmin = useSelector(selectIsAdmin);
  const [availableOnSite, setAvailableOnSite] = useState(
    property.availableOnSite
  );

  const switchPropertySiteAvailability = async () => {
    if (isAdmin) {
      setAvailableOnSite(!availableOnSite);
      await axiosInstance.post(
        `/properties/${property.id}/switchSiteAvailability`
      );
    }
  };

  return (
    <Space>
      <BulbOutlined
        style={availableOnSite ? { color: '#1890ff' } : {}}
        onClick={switchPropertySiteAvailability}
      />
      <NotificationOutlined />
      <StopOutlined />
      <FileOutlined />
      <Link to={`/admin/properties/${property.id}/update`}>
        <SettingOutlined />
      </Link>
    </Space>
  );
};

export default TableOperations;
