import moment from 'moment';
import React from 'react';
import './ColorDate.css';
const ColorDate = ({ availableFromModifiedAt, availableFrom }) => {
  if (!availableFromModifiedAt) {
    return <span>{availableFrom}</span>;
  }
  const now = moment();
  const availableFromModifiedAtDate = moment(availableFromModifiedAt);
  const diff = now.diff(availableFromModifiedAtDate, 'hours', true);
  if (diff < 48 || diff < 0) {
    return <span className='two-hour'>{availableFrom} </span>;
  }
  if (diff < 14 * 24) {
    return <span className='two-weeks'> {availableFrom}</span>;
  }
  return <span className='after-two-weeks'> {availableFrom}</span>;
};

export default ColorDate;
