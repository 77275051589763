export const dashboardOptions = [
  {
    label: 'Any', value: 'any',
  },
  {
    label: 'Ref. Number', value: 'id',
  },
  {
    label: 'Owner name', value: 'ownerName',
  },
  {
    label: 'Owner Mobile', value: 'ownerContactNumber',
  },
  {
    label: 'Property Address', value: 'address',
  },
  {
    label: 'Agent', value: 'agentName',
  },
  {
    label: 'Location', value: 'locationName',
  }
]

export const featuresOptions = [
  { label: 'Air Conditioning', value: 'airConditioning' },
  { label: 'Airspace', value: 'airspace' },
  { label: 'Balcony', value: 'balcony' },
  { label: 'Basement', value: 'basement' },
  { label: 'Bath tub', value: 'bathTub' },
  { label: 'Cable TV', value: 'cableTv' },
  { label: 'Central Heating', value: 'centralHeating' },
  { label: 'Dishwasher', value: 'dishwasher' },
  { label: 'Finished', value: 'finished' },
  { label: 'Fireplace', value: 'fireplace' },
  { label: 'Form H', value: 'formH' },
  { label: 'Furnished', value: 'furnished' },
  { label: 'Freehold', value: 'freehold' },
  { label: 'Garden', value: 'garden' },
  { label: 'Garage', value: 'garage' },
  { label: 'Ground Floor', value: 'groundFloor' },
  { label: 'Ground Rent', value: 'groundRent' },
  { label: 'Internet', value: 'internet' },
  { label: 'Jacuzzi', value: 'jacuzzi' },
  { label: 'Lift', value: 'lift' },
  { label: 'TV', value: 'tv' },
  { label: 'Open Plan', value: 'openPlan' },
  { label: 'Use Of Roof', value: 'useOfRoof' },
  { label: 'Parking Space', value: 'parkingSpace' },
  { label: 'Pet Friendly', value: 'petFriendly' },
  { label: 'Roof Terrace', value: 'roofTerrace' },
  { label: 'Sea View', value: 'seaView' },
  { label: 'Seafront', value: 'seafront' },
  { label: 'Shell Form', value: 'shellForm' },
  { label: 'Semi Detached', value: 'semiDetached' },
  { label: 'Semi Finished', value: 'semiFinished' },
  { label: 'Studio', value: 'studio' },
  { label: 'Swimming Pool', value: 'swimmingPool' },
  { label: 'Terrace', value: 'terrace' },
  { label: 'Tumble Dryer', value: 'tumbleDryer' },
  { label: 'Unfurnished', value: 'unfurnished' },
  { label: 'Uncovered', value: 'uncovered' },
  { label: 'Utility Room', value: 'utilityRoom' },
  { label: 'Valley View', value: 'valleyView' },
  { label: 'Washing Machine', value: 'washingMachine' },
  { label: 'Wheelchair Accessible', value: 'wheelchairAccessible' },
  { label: 'Yard', value: 'yard' },
];

