import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PicturesWall from '../../components/PictureWall/PictureWall';
import { selectName } from '../../slice/agentSlice';
import {
  selectAssetTypes,
  selectLocationTypes,
  selectPropertyTypes,
} from '../../slice/typesSlice';
import axiosInstance from '../../util/api';
import { featuresOptions } from '../../util/constants';
import { convertPropertyValuesToPropertyRequestDto } from '../../util/propertyMapper';
import './CreatePropertyPage.css';

const { Option } = Select;
const { Search, TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreatePropertyPage = () => {
  const [form] = Form.useForm();
  const agentName = useSelector(selectName);
  const assetTypes = useSelector(selectAssetTypes);
  const locationTypes = useSelector(selectLocationTypes);
  const propertyTypes = useSelector(selectPropertyTypes);

  const [loading, setLoading] = useState(false);
  const [allOwners, setAllOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [listOfPictures, setListOfPictures] = useState([]);
  const [isSLPriceOkay, setIsSLPriceOkay] = useState(false);
  const [isLLPriceOkay, setIsLLPriceOkay] = useState(false);

  useEffect(() => {
    const fetchOwners = async () => {
      setLoading(true);
      try {
        const ownerResponse = await axiosInstance.get(`/owners/`);
        setAllOwners(ownerResponse.data);
        setFilteredOwners(ownerResponse.data);
      } finally {
        setLoading(false);
      }
    };
    fetchOwners();
  }, []);

  useEffect(() => {
    form.validateFields(['slPrice']);
    form.validateFields(['llPrice']);
  }, [isSLPriceOkay, isLLPriceOkay]);

  const onPictureUpload = (fileList = []) => {
    const newFileList = fileList.filter((file) => file.status === 'done');
    const pictureList = newFileList.map((file) => file.response.url);
    setListOfPictures(pictureList);
  };

  const onFinish = async (values) => {
    values.pictures = listOfPictures;
    await createProperty(convertPropertyValuesToPropertyRequestDto(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const createProperty = async (propertyRequestDto) => {
    setLoading(true);
    try {
      await axiosInstance.post('/properties', propertyRequestDto);
      message.success('Property successfully created');
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (searchValue) => {
    let filteredNames = allOwners.filter((owner) =>
      owner.contactNumber.includes(searchValue)
    );
    setFilteredOwners(filteredNames);
  };

  const onNumberChange = (value, key) => {
    if (key === 'slPrice') {
      value && Number.isInteger(value) && value > 0
        ? setIsSLPriceOkay(true)
        : setIsSLPriceOkay(false);
    } else if (key === 'llPrice') {
      value && Number.isInteger(value) && value > 0
        ? setIsLLPriceOkay(true)
        : setIsLLPriceOkay(false);
    }
  };

  return (
    <div className='create-property-page-container'>
      <h2>Create property</h2>
      <Form
        {...layout}
        name='createProperty'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='create-property-page-form'
        form={form}
        initialValues={{
          agent: agentName,
        }}
      >
        <Form.Item
          label='Asset type'
          name='assetTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose asset type',
            },
          ]}
        >
          <Select style={{ width: 150 }}>
            {assetTypes.map((assetType) => (
              <Option key={assetType.id} value={assetType.id}>
                {assetType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Property type'
          name='propertyTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose property type',
            },
          ]}
        >
          <Select style={{ width: 250 }}>
            {propertyTypes.map((propertyType) => (
              <Option key={propertyType.id} value={propertyType.id}>
                {propertyType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Owner name'
          name='ownerId'
          rules={[
            {
              required: true,
              message: 'Please choose a owner',
            },
          ]}
        >
          <Select style={{ width: 250 }}>
            {filteredOwners.map((filteredOwner) => (
              <Option key={filteredOwner.id} value={filteredOwner.id}>
                <span>{`${filteredOwner.name} ${filteredOwner.surname}`}</span>
                <br />
                <span className='create-property-page-owner-mobile'>{`${filteredOwner.contactNumber}`}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name='ownerMobile' label='Owner Mobile'>
          <Search
            placeholder="Search owner's number"
            onSearch={onSearch}
            onChange={(event) => onSearch(event.target.value)}
            style={{ width: 250 }}
            allowClear
          />
        </Form.Item>

        <Form.Item
          label='Location'
          name='locationTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose a location',
            },
          ]}
        >
          <Select style={{ width: 250 }}>
            {locationTypes.map((locationType) => (
              <Option key={locationType.id} value={locationType.id}>
                {locationType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Property full address'
          name='address'
          rules={[
            {
              required: true,
              message: 'Please input a address',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Added by' name='agent'>
          <Input disabled={true} style={{ width: 250 }} />
        </Form.Item>

        <Form.Item label='Square meters' name='squareMeters'>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label='Number of bedrooms' name='numberOfBedrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Number of bathrooms' name='numberOfBathrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Available from' name='availableFrom'>
          <DatePicker format='DD-MM-YYYY' />
        </Form.Item>
        <Form.Item label='Condition' name='condition'>
          <Select style={{ width: 150 }}>
            {['Ultra modern', 'Modern', 'Average'].map((conditionType) => (
              <Option key={conditionType} value={conditionType}>
                {conditionType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Video link' name='videoLink'>
          <Input />
        </Form.Item>
        <Form.Item label='Features' name='features'>
          <Checkbox.Group>
            <Row>
              {featuresOptions.map(({ label, value }) => {
                return (
                  <Col key={value} span={8}>
                    <Checkbox
                      key={value}
                      value={value}
                      style={{ lineHeight: '32px' }}
                    >
                      {label}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <TextArea />
        </Form.Item>
        <Form.Item label='Comments' name='comments'>
          <TextArea />
        </Form.Item>
        {/* <Form.Item
          label='Price (€)'
          name='price'
          rules={[
            {
              required: true,
              message: 'Please enter a price',
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item> */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.assetTypeId !== currentValues.assetTypeId
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('assetTypeId') === 2 ? (
              <>
                <Form.Item
                  name='slPrice'
                  label='SL Price (€)'
                  rules={[
                    {
                      required: !(isSLPriceOkay || isLLPriceOkay),
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    onChange={(value) => onNumberChange(value, 'slPrice')}
                  />
                </Form.Item>
                <Form.Item
                  name='llPrice'
                  label='LL Price (€)'
                  rules={[
                    {
                      required: !(isSLPriceOkay || isLLPriceOkay),
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    onChange={(value) => onNumberChange(value, 'llPrice')}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label='Sale Price (€)'
                name='salePrice'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a price',
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item label='Pictures' name='pictures'>
          <PicturesWall onPictureUpload={onPictureUpload} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit' disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreatePropertyPage;
