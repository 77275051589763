import React from 'react';
import 'antd/dist/antd.css';
import LoginPage from './pages/Login/LoginPage';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AdminPage from './pages/Admin/AdminPage';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from './slice/agentSlice';

const App = () => {
  let isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Router>
      <div className='app-container'>
        <Switch>
          <Route path='/login'>
            {isLoggedIn ? <Redirect to='/admin/dashboard' /> : <LoginPage />}
          </Route>
          <PrivateRoute path='/admin'>
            <AdminPage />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  let isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default App;
