import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import axiosInstance, { addAuthHeader } from '../util/api';
import { saveAgentState, loadAgentState } from '../util/localStorage';

const getInitialState = () => {
  const initialState = {
    authToken: '',
    username: '',
    name: '',
    isAdmin: false,
    isLoggedIn: false,
    isLoading: false,
    exp: null,
};
  const agentState = loadAgentState();
  //check jwt expiration
  if(agentState?.exp && (new Date(agentState.exp * 1000) < new Date())){
    return initialState;
  }
  if(agentState?.authToken) addAuthHeader(agentState.authToken);
  return agentState || initialState;
}

export const loginAgent = createAsyncThunk(
    'agent/login',
    async (agentLogin, { rejectWithValue }) => {
      try{
        const response = await axiosInstance.post("/agents/login", agentLogin)
        response.data.remember = agentLogin.remember;
        console.log("RESOLVED RESPONSE", response);
        return response.data;
      }catch(error){
        console.log("REJECTED RESPONSE",error);
        return error.response ? rejectWithValue(error.response.data.message) : rejectWithValue(error.message);
      }
    }
  );

export const agentSlice = createSlice({
    name: 'agent',
    initialState: getInitialState(),
    reducers: {
      logout: (state) => {
        state.authToken= '';
        state.username= '';
        state.name='';
        state.isAdmin= false;
        state.isLoggedIn= false;
        state.isLoading= false;
        state.exp=null;
        saveAgentState(state);
      },
    },
    extraReducers: (builder) => {
    builder
        .addCase(loginAgent.pending, (state) => {
            state.isLoading = true
        })
        .addCase(loginAgent.fulfilled, (state, action) => {
            state.authToken = action.payload.token;
            addAuthHeader(state.authToken);
            const jwt = state.authToken.split("Bearer ").pop();
            const decodedJwt = jwt_decode(jwt);
            state.username = decodedJwt.username;
            state.name = decodedJwt.name;
            state.isAdmin = decodedJwt.isAdmin;
            state.exp = decodedJwt.exp;
            state.isLoggedIn = true;
            state.isLoading = false;
            if(action.payload.remember) saveAgentState(state);
        })
        .addCase(loginAgent.rejected, (state, action) => {
            state.errorMessage = action.payload
            state.isLoggedIn = false;
            state.isLoading = false;
            saveAgentState(state);
        });
    },
});
export const selectUsername = (state) => state.agent.username;
export const selectName = (state) => state.agent.name;
export const selectIsAdmin = (state) => state.agent.isAdmin;
export const selectIsLoggedIn = (state) => state.agent.isLoggedIn;

export const { logout } = agentSlice.actions;

export default agentSlice.reducer;
