import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../util/api';

export const fetchTypes = createAsyncThunk(
    'types/fetch',
    async (_,{ rejectWithValue }) => {
      try{
        const response = await axiosInstance.get("/types")
        console.log("RESOLVED RESPONSE TYPES", response);
        return response.data;
      }catch(error){
        console.log("REJECTED RESPONSE TYPES",error);
        return error.response ? rejectWithValue(error.response.data.message) : rejectWithValue(error.message);
      }
    }
  );

export const typesSlice = createSlice({
    name: 'types',
    initialState: {
      assetTypes: [],
      propertyTypes: [],
      locationTypes: [],
      isLoading: false,
      errorMessage: ''
  },
    reducers: {
    },
    extraReducers: (builder) => {
    builder
        .addCase(fetchTypes.pending, (state) => {
            state.isLoading = true
        })
        .addCase(fetchTypes.fulfilled, (state, action) => {
            state.assetTypes = action.payload.assetTypes;
            state.propertyTypes = action.payload.propertyTypes;
            state.locationTypes = action.payload.locationTypes;
            state.isLoading = false;
        })
        .addCase(fetchTypes.rejected, (state, action) => {
            state.errorMessage = action.payload
        });
    },
});
export const selectAssetTypes = (state) => state.types.assetTypes;
export const selectLocationTypes = (state) => state.types.locationTypes;
export const selectPropertyTypes = (state) => state.types.propertyTypes;

export default typesSlice.reducer;
