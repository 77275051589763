import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PicturesWall from '../../components/PictureWall/PictureWall';
import { selectIsAdmin } from '../../slice/agentSlice';
import {
  selectAssetTypes,
  selectLocationTypes,
  selectPropertyTypes,
} from '../../slice/typesSlice';
import axiosInstance from '../../util/api';
import { featuresOptions } from '../../util/constants';
import {
  convertPropertyDtoToFeatures,
  convertPropertyValuesToPropertyRequestDto,
  generatePictureWallFilelist,
} from '../../util/propertyMapper';
import './UpdatePropertyPage.css';

const { Option } = Select;
const { Search, TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const UpdatePropertyPage = () => {
  const [form] = Form.useForm();
  const assetTypes = useSelector(selectAssetTypes);
  const locationTypes = useSelector(selectLocationTypes);
  const propertyTypes = useSelector(selectPropertyTypes);
  const isAdmin = useSelector(selectIsAdmin);

  const [loading, setLoading] = useState(false);
  const [allOwners, setAllOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [listOfPictures, setListOfPictures] = useState([]);
  const [initialListOfPictures, setInitialListOfPictures] = useState([]);
  const [property, setProperty] = useState({
    assetTypeId: 0,
    propertyTypeId: 0,
    ownerId: 0,
    ownerMobileSearch: '',
    locationTypeid: 0,
    address: '',
    agent: 0,
    squareMeters: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    availableFrom: '',
    videoLink: '',
    features: [],
    description: '',
    comments: '',
    availableOnSite: false,
    condition: '',
    slPrice: '',
    llPrice: '',
    salePrice: '',
    pictures: [],
  });
  const [agents, setAgents] = useState([]);
  const { propertyId } = useParams();

  useEffect(() => {
    const fetchData = async (propertyId) => {
      setLoading(true);
      try {
        const ownerResponse = await axiosInstance.get(`/owners`);
        const agentsResponse = await axiosInstance.get('/agents');
        setAgents(agentsResponse.data);
        setAllOwners(ownerResponse.data);
        setFilteredOwners(ownerResponse.data);
        const propertyResponse = await axiosInstance.get(
          `/properties/${propertyId}`
        );
        setProperty(propertyResponse.data);
        const initialFields = propertyResponse.data;
        initialFields.assetTypeId = propertyResponse.data.assetType.id;
        initialFields.propertyTypeId = propertyResponse.data.propertyType.id;
        initialFields.locationTypeId = propertyResponse.data.locationType.id;
        initialFields.ownerId = propertyResponse.data.owner.id;
        initialFields.ownerContactNumber =
          propertyResponse.data.owner.contactNumber;
        initialFields.agentId = propertyResponse.data.agent.id;
        initialFields.availableFrom =
          propertyResponse.data.availableFrom &&
          moment(propertyResponse.data.availableFrom, 'YYYY-MM-DD');
        initialFields.features = convertPropertyDtoToFeatures(
          propertyResponse.data
        );
        setInitialListOfPictures(
          generatePictureWallFilelist(propertyResponse.data.pictures)
        );
        onPictureUpload(
          generatePictureWallFilelist(propertyResponse.data.pictures)
        );
        form.setFieldsValue(initialFields);
      } finally {
        setLoading(false);
      }
    };
    fetchData(propertyId);
  }, [propertyId, form]);

  const onPictureUpload = (fileList = []) => {
    const newFileList = fileList.filter((file) => file.status === 'done');
    const pictureList = newFileList.map((file) => file.response.url);
    setListOfPictures(pictureList);
    console.log('newFileList', newFileList);
  };

  const onFinish = async (values) => {
    console.log(values);
    values.pictures = listOfPictures;
    await updateProperty(convertPropertyValuesToPropertyRequestDto(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const updateProperty = async (propertyRequestDto) => {
    setLoading(true);
    try {
      await axiosInstance.put(`/properties/${propertyId}`, propertyRequestDto);
      message.success('Property successfully updated');
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (searchValue) => {
    let filteredNames = allOwners.filter((owner) =>
      owner.contactNumber.includes(searchValue)
    );
    setFilteredOwners(filteredNames);
  };

  return (
    <div className='update-property-page-container'>
      <h2>Update property</h2>
      <Form
        {...layout}
        name='updateProperty'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='update-property-page-form'
        form={form}
      >
        <Form.Item
          label='Asset type'
          name='assetTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose asset type',
            },
          ]}
        >
          <Select style={{ width: 150 }} disabled={!isAdmin}>
            {assetTypes.map((assetType) => (
              <Option key={assetType.id} value={assetType.id}>
                {assetType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Property type'
          name='propertyTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose property type',
            },
          ]}
        >
          <Select style={{ width: 250 }} disabled={!isAdmin}>
            {propertyTypes.map((propertyType) => (
              <Option key={propertyType.id} value={propertyType.id}>
                {propertyType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Owner name'
          name='ownerId'
          rules={[
            {
              required: true,
              message: 'Please choose a owner',
            },
          ]}
        >
          <Select style={{ width: 250 }} disabled={!isAdmin}>
            {filteredOwners.map((filteredOwner) => (
              <Option key={filteredOwner.id} value={filteredOwner.id}>
                {`${filteredOwner.name} ${filteredOwner.surname}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name='ownerContactNumber' label='Owner Mobile'>
          <Input disabled={true} style={{ width: 250 }} />
        </Form.Item>

        <Form.Item name='ownerMobileSearch' label='Owner Mobile Search'>
          <Search
            placeholder="Search owner's number"
            onSearch={onSearch}
            onChange={(event) => onSearch(event.target.value)}
            style={{ width: 250 }}
            allowClear
            disabled={!isAdmin}
          />
        </Form.Item>

        <Form.Item
          label='Location'
          name='locationTypeId'
          rules={[
            {
              required: true,
              message: 'Please choose a location',
            },
          ]}
        >
          <Select style={{ width: 250 }} disabled={!isAdmin}>
            {locationTypes.map((locationType) => (
              <Option key={locationType.id} value={locationType.id}>
                {locationType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Property full address'
          name='address'
          rules={[
            {
              required: true,
              message: 'Please input a address',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Added by' name='agentId'>
          <Select style={{ width: 250 }} disabled={!isAdmin}>
            {agents.map((agent) => (
              <Option key={agent.id} value={agent.id}>
                {agent.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Square meters' name='squareMeters'>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label='Number of bedrooms' name='numberOfBedrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Number of bathrooms' name='numberOfBathrooms'>
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item label='Available from' name='availableFrom'>
          <DatePicker format='DD-MM-YYYY' />
        </Form.Item>
        <Form.Item label='Condition' name='condition'>
          <Select style={{ width: 150 }}>
            {['Ultra modern', 'Modern', 'Average'].map((conditionType) => (
              <Option key={conditionType} value={conditionType}>
                {conditionType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Video link' name='videoLink'>
          <Input />
        </Form.Item>
        <Form.Item label='Features' name='features'>
          <Checkbox.Group>
            <Row>
              {featuresOptions.map(({ label, value }) => {
                return (
                  <Col key={value} span={8}>
                    <Checkbox
                      key={value}
                      value={value}
                      style={{ lineHeight: '32px' }}
                    >
                      {label}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <TextArea />
        </Form.Item>
        <Form.Item label='Comments' name='comments'>
          <TextArea />
        </Form.Item>
        <Form.Item
          label='Available on site'
          name='availableOnSite'
          valuePropName='checked'
        >
          <Switch disabled={!isAdmin}/>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.assetTypeId !== currentValues.assetTypeId
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('assetTypeId') === 2 ? (
              <>
                <Form.Item name='slPrice' label='SL Price (€)'>
                  <InputNumber min={1} />
                </Form.Item>
                <Form.Item name='llPrice' label='LL Price (€)'>
                  <InputNumber min={1} />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label='Sale Price (€)'
                name='salePrice'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a price',
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item label='Pictures' name='pictures'>
          <PicturesWall
            onPictureUpload={onPictureUpload}
            initialListOfPictures={initialListOfPictures}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit' disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdatePropertyPage;
