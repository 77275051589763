import { Button, DatePicker, Form, Input, message, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PicturesWall from '../../components/PictureWall/PictureWall';
import { selectName } from '../../slice/agentSlice';
import axiosInstance from '../../util/api';
import './UpdateAgentPage.css';
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const UpdateAgentPage = () => {
  const { agentId } = useParams();
  const [form] = Form.useForm();
  const agentName = useSelector(selectName);
  const [loading, setLoading] = useState(false);
  const [listOfPictures, setListOfPictures] = useState([]);
  const [initialListOfPictures, setInitialListOfPictures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const agentResponse = await axiosInstance.get(`/agents/${agentId}`);
        const initialFields = agentResponse.data;
        initialFields.dateOfBirth =
          agentResponse.data.dateOfBirth &&
          moment(agentResponse.data.dateOfBirth, 'YYYY-MM-DD');
        setInitialListOfPictures(
          agentResponse.data.image
            ? [
                {
                  uid: -1,
                  name: 'image.png',
                  status: 'done',
                  url: agentResponse.data.image,
                  response: { url: agentResponse.data.image },
                },
              ]
            : []
        );
        onPictureUpload(
          agentResponse.data.image
            ? [
                {
                  uid: -1,
                  name: 'image.png',
                  status: 'done',
                  url: agentResponse.data.image,
                  response: { url: agentResponse.data.image },
                },
              ]
            : []
        );

        form.setFieldsValue(initialFields);
      } finally {
        setLoading(false);
      }
    };
    fetchData(agentId);
  }, [agentId, form]);

  const onFinish = async (values) => {
    values.image = listOfPictures[0];
    console.log(values);
    await updateAgent(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const updateAgent = async (values) => {
    delete values.agent;
    setLoading(true);
    try {
      await axiosInstance.put(`/agents/${agentId}`, values);
      message.success('Agent successfully updated');
    } finally {
      setLoading(false);
    }
  };

  const onPictureUpload = (fileList = []) => {
    const newFileList = fileList.filter((file) => file.status === 'done');
    const pictureList = newFileList.map((file) => file.response.url);
    setListOfPictures(pictureList);
    console.log('newFileList', newFileList);
  };

  return (
    <div className='update-agent-page-container'>
      <h2>Update Agent</h2>
      <Form
        {...layout}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='update-agent-page-form'
        form={form}
      >
        <Form.Item
          label='Name and Surname'
          name='name'
          rules={[
            {
              required: true,
              message: "Please input agents's name and surname!",
            },
            {
              max: 100,
            },
          ]}
        >
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item label='Residential Address' name='address'>
          <Input />
        </Form.Item>

        <Form.Item label='Contact number' name='mobileNumber'>
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              type: 'email',
            },
          ]}
        >
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item label='ID / Passport number' name='idNumber'>
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item label='VAT number' name='vatNumber'>
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item label='Branch address' name='branchAddress'>
          <Input />
        </Form.Item>

        <Form.Item label='Nationality' name='nationality'>
          <Input style={{ width: 300 }} />
        </Form.Item>

        <Form.Item label='Date of birth' name='dateOfBirth'>
          <DatePicker format='DD-MM-YYYY' />
        </Form.Item>

        <Form.Item label='IBAN' name='iban'>
          <Input />
        </Form.Item>

        <Form.Item label='Bank name' name='bankName'>
          <Input />
        </Form.Item>

        <Form.Item label='Swift code' name='swiftCode'>
          <Input />
        </Form.Item>

        <Form.Item label='Title' name='title'>
          <Input />
        </Form.Item>

        <Form.Item label='Description' name='description'>
          <TextArea />
        </Form.Item>

        <Form.Item
          label='Available on site'
          name='availableOnSite'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label='Username'
          name='username'
          rules={[
            {
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            {
              max: 100,
              min: 8,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Image' name='image'>
          <PicturesWall
            initialListOfPictures={initialListOfPictures}
            onPictureUpload={onPictureUpload}
            singlePicture={true}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit' disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateAgentPage;
