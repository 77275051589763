import axios from 'axios';
import { message } from 'antd';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

export const addAuthHeader = (token) => {
  axiosInstance.defaults.headers.common['Authorization'] = token;
}

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log(error);
    message.error('Bad request');
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('INTERCEPTOR ERROR', {error});
    message.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);


export default axiosInstance;

