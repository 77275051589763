import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAssetTypes, selectPropertyTypes } from '../../slice/typesSlice';
import { dashboardOptions } from '../../util/constants';
import './DashboardSearchRow.css';

const { Option } = Select;

const DashboardSearchRow = ({ handleTableSearch }) => {
  const [form] = Form.useForm();
  const assetTypes = useSelector(selectAssetTypes);
  const propertyTypes = useSelector(selectPropertyTypes);
  const onFinish = async (values) => {
    console.log(values);
    handleTableSearch(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='create-owner-page-container'>
      <Form
        layout='inline'
        name='table-row-search'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item label='Asset type' name='assetTypeId'>
          <Select style={{ width: 150 }} allowClear>
            {assetTypes.map((assetType) => (
              <Option key={assetType.id} value={assetType.id}>
                {assetType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Property type' name='propertyTypeId'>
          <Select style={{ width: 300 }} allowClear mode='multiple'>
            {propertyTypes.map((propertyType) => (
              <Option key={propertyType.id} value={propertyType.id}>
                {propertyType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label=' Search by' name='searchBy'>
          <Select style={{ width: 160 }} allowClear>
            {dashboardOptions.map((dashboardOption) => (
              <Option key={dashboardOption.value} value={dashboardOption.value}>
                {dashboardOption.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Search value' name='searchValue'>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DashboardSearchRow;
