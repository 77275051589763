import { featuresOptions } from "./constants";

export const convertPropertyValuesToPropertyRequestDto = (formValues) => {
    const featuresObject = {};
    formValues?.features?.forEach(key => featuresObject[key] = true);
    const propertyRequestDto = {...formValues, ...featuresObject};
    propertyRequestDto.availableFrom = formValues?.availableFrom?.format("YYYY-MM-DD");
    delete propertyRequestDto.features;
    return propertyRequestDto;
}

export const convertPropertyDtoToFeatures = (property) => {
    const features = [];
    featuresOptions.forEach((feature)=>{
        property[feature.value]===true && features.push(feature.value);
    })
    return features;
}

export const generatePictureWallFilelist = (pictures) => {
    return pictures.map((picture, index)=> {
        return {
            uid: -index,
            name: "image.png",
            status: 'done',
            url: picture.url,
            response: {url: picture.url}
        }
    })
}