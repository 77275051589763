import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import PropertiesTable from '../../components/PropertiesTable/PropertiesTable';
import DashboardSearchRow from '../../components/DashboardSearchRow/DashboardSearchRow';
import axiosInstance from '../../util/api';
import './DashboardPage.css';

const DashboardPage = () => {
  const [data, setData] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: false,
    total: 500
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTableData({ pagination });
  }, [queryString]);

  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({ pagination });
  };

  const fetchTableData = async ({ pagination }) => {
    setLoading(true);
    try {
      const dataResponse = await axiosInstance.get(
        `/properties?pageNumber=${pagination.current - 1}` +
          (queryString ? `&${queryString}` : '')
      );
      setData(dataResponse.data.searchResults);
      setPagination({ ...pagination, total: dataResponse.data.totalPages*pagination.pageSize });
    } finally {
      setLoading(false);
    }
  };

  const handleTableSearch = async (values) => {
    console.log(values);
    if (values.searchBy && values.searchValue) {
      values[values.searchBy] = values.searchValue;
    }
    delete values.searchBy;
    delete values.searchValue;
    const queryString = mapValuesToRequestString(values);
    setQueryString(queryString);
  };

  const mapValuesToRequestString = (values) => {
    const searchParams = new URLSearchParams();
    Object.keys(values).forEach((key) => {
      if (!values[key]) return;
      if (key === 'propertyTypeId') {
        values.propertyTypeId.forEach((propertyTypeId) =>
          searchParams.append('propertyTypeId', propertyTypeId)
        );
        return;
      }
      searchParams.append(key, values[key]);
    });
    return searchParams.toString();
  };

  return (
    <>
      <Row>
        <Col span={6} offset={1}>
          <p className='dashboard-page-agent-info'>--agent daily goals--</p>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={22}>
          <div className='properties-table-search-row'>
            <DashboardSearchRow handleTableSearch={handleTableSearch} />
          </div>
          <PropertiesTable
            data={data}
            handleTableChange={handleTableChange}
            pagination={pagination}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
